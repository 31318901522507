import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const ContactUs = () => (
	<Layout headerColor='yellow'>
      <SEO title="Home" />

      <h1>Contact Us Coming Soon</h1>
  	</Layout>
)

export default ContactUs;